{
  "notFound": "Cette page n'existe pas :'(",
  "traducteur": "",
  "contact-us": "",
  "head": {
    "meta": {
      "description": "Ce test de pureté consiste en quelques questions (ne les comptez pas) portant pour la plupart, sur les thèmes de l'alcool, du sexe, et de la drogue...",
      "title": "Test de pureté"
    }
  },
  "menu": {
    "test-purete": "Test de pureté",
    "random-shitpost": "random-shitpost.com"
  },
  "home-page": {
    "title": "test de pureté",
    "subtitle": "Les questions de ce test portent sur les thèmes de l'alcool, du sexe, et de la drogue...",
    "intro-text": {
      "line-1": "",
      "line-2": "",
      "line-3": ""
    },
    "beware": "Oserez-vous y répondre ? 😈😇",
    "ad-block": {
      "title": "⬇️Découvre notre nouveau site inutile⬇️"
    },
    "begin-griffor-btn": "👉 Test de pureté classique",
    "begin-boudah-btn": "Version alternative",
    "begin-michou-btn": "Version Michou !",
    "continue-btn": "Reprendre où j'en étais",
    "ad-warning": {
      "warning": "☝️ Attention, si une publicité s'ouvre, faites 'retour' ☝️",
      "our-test": "(notre test est et sera",
      "always": "toujours",
      "free": "gratuit) 😇"
    },
    "already": "",
    "tests-made": " malades ont passés le test ! 🎉"
  },
  "possibleAnswers": {
    "oui": "oui",
    "non": "non"
  },
  "questions": [
    {
      "id": "riMalheur",
      "question": "Avez-vous déjà ri du malheur de quelqu'un ?"
    },
    {
      "id": "riMental",
      "question": "Avez vous déjà ri d'une personne mentalement ou physiquement handicapée ?"
    },
    {
      "id": "poserLapin",
      "question": "Avez-vous déjà posé un lapin à quelqu'un ?"
    },
    {
      "id": "foisArrete",
      "question": "Combien de fois avez-vous déjà été arrêté(e) ?"
    },
    {
      "id": "gouterAlcool",
      "question": "Avez-vous déjà goûté à l'alcool ?"
    },
    {
      "id": "dejaIvre",
      "question": "Avez-vous déjà été ivre ? "
    },
    {
      "id": "ivre48h",
      "question": "Avez-vous déjà été ivre pendant plus de 48 heures ?"
    },
    {
      "id": "tomberIvre",
      "question": "Etes-vous déjà tombé(e) parce que vous étiez trop ivre ? "
    },
    {
      "id": "pisserDessus",
      "question": "Vous êtes-vous déjà pissé dessus quand vous étiez ivre ?"
    },
    {
      "id": "participerBoire",
      "question": "Avez-vous déjà participé à des jeux à boire ? "
    },
    {
      "id": "buVomir",
      "question": "Avez-vous déjà bu assez pour vomir ? "
    },
    {
      "id": "vomiQuelquun",
      "question": "Avez-vous déjà vomi sur vous ou sur quelqu'un d'autre ?"
    },
    {
      "id": "virerNuit",
      "question": "Vous êtes vous déjà fait sortir de force d'un bar ou d'une boîte de nuit ?"
    },
    {
      "id": "barathon",
      "question": "Avez-vous déjà participé à un barathon ? (Tous les bars d'une ville ou d'une rue) ?"
    },
    {
      "id": "alcoolDejeuner",
      "question": "Avez-vous déjà bu de l'alcool pour le petit-déjeuner ?"
    },
    {
      "id": "alcool3fois",
      "question": "Buvez vous de l'alcool au moins 3 fois par semaine ?"
    },
    {
      "id": "jourPlus",
      "question": "Nombre de jours en plus des trois jours réguliers ?"
    },
    {
      "id": "dormiToilette",
      "question": "Avez-vous déjà dormi dans les toilettes ?"
    },
    {
      "id": "volontaireToilette",
      "question": "...Avez-vous vous-même été volontaire pour dormir à cet endroit ?"
    },
    {
      "id": "evanouiBar",
      "question": "Vous êtes-vous déjà endormi(e) ou évanoui(e) dans un bar ou une boîte ?"
    },
    {
      "id": "avalerVomi",
      "question": "Avez-vous déjà avalé votre vomi ou celui de quelqu'un d'autre ?"
    },
    {
      "id": "vomiLieuPublic",
      "question": "Avez-vous déjà vomi dans un lieu public ?"
    },
    {
      "id": "urinerPasPrevu",
      "question": "Avez-vous déjà uriné à un endroit qui n'était pas prévu à cet usage ?"
    },
    {
      "id": "essayerDrogue",
      "question": "Avez-vous déjà essayé la drogue ?"
    },
    {
      "id": "drogueEssaye",
      "question": "Indiquez le nombre de drogues essayées :"
    },
    {
      "id": "defonce48h",
      "question": "Avez-vous déjà été défoncé pendant plus de 48 heures ?"
    },
    {
      "id": "drogueRegulier",
      "question": "Consommez-vous des drogues régulièrement ?"
    },
    {
      "id": "plus4fois",
      "question": "Plus de 4 fois par semaine ? "
    },
    {
      "id": "acheteDrogue",
      "question": "Avez-vous déjà acheté de la drogue ?"
    },
    {
      "id": "venduDrogue",
      "question": "Avez-vous déjà vendu de la drogue ?"
    },
    {
      "id": "venduDrogueFinance",
      "question": "Avez-vous déjà vendu de la drogue pour financer votre propre consommation ?"
    },
    {
      "id": "badTrip",
      "question": "Avez-vous déjà été en bad trip ?"
    },
    {
      "id": "initierDrogue",
      "question": "Avez-vous déjà initié quelqu'un aux drogues en général ?"
    },
    {
      "id": "profiterQuelquun",
      "question": "Avez-vous déjà profité de quelqu'un pendant qu'il/elle était ivre, drogué(e), ou momentanément handicapé(e) ?"
    },
    {
      "id": "sextape",
      "question": "Avez-vous déjà fait une sextape ?"
    },
    {
      "id": "rdvAmoureux",
      "question": "Avez-vous déjà eu un rendez-vous amoureux ?"
    },
    {
      "id": "embrasseQuelquun",
      "question": "Avez-vous déjà embrassé et/ou peloté quelqu'un ?"
    },
    {
      "id": "embrasserOrgasme",
      "question": "Avez-vous déjà embrassé et/ou peloté quelqu'un jusqu'à l'orgasme ? "
    },
    {
      "id": "relationsSexuelles",
      "question": "Avez-vous déjà eu des relations sexuelles ?"
    },
    {
      "id": "payerSexe",
      "question": "Avez-vous déjà payé pour du sexe ?"
    },
    {
      "id": "sexeArgent",
      "question": "Avez-vous déjà eu des relations sexuelles contre de l'argent ?"
    },
    {
      "id": "impliqueFellation",
      "question": "Avez-vous déjà été impliqué(e) dans une fellation ou un cunnilingus ?"
    },
    {
      "id": "jusquaOrgasme",
      "question": "Jusqu'à l'orgasme ? "
    },
    {
      "id": "rapportAnal",
      "question": "Avez-vous déjà eu un rapport anal ?"
    },
    {
      "id": "jusquaOrgasme2",
      "question": "Jusqu'à l'orgasme ? "
    },
    {
      "id": "fait69",
      "question": "Avez-vous déjà fait un 69 ?"
    },
    {
      "id": "ist",
      "question": "Avez-vous déjà eu une IST (infection sexuellement transmissible) ?"
    },
    {
      "id": "sansContraception",
      "question": "Avez-vous déjà eu des relations sexuelles sans utiliser de moyen de contraception ?"
    },
    {
      "id": "avortement",
      "question": "Avez-vous déjà subi ou été à l'origine d'un avortement ?"
    },
    {
      "id": "plusDunePersonne",
      "question": "Avez-vous déjà eu des relations sexuelles avec plus d'une personne en même temps ?"
    },
    {
      "id": "plusDeuxPersonneSemaine",
      "question": "Avez-vous déjà eu des relations sexuelles avec plus de deux personnes dans la même semaine ?"
    },
    {
      "id": "sexeLieuPublic",
      "question": "Avez-vous déjà eu des relations sexuelles dans un lieu public ?"
    },
    {
      "id": "blessureSexe",
      "question": "Avez-vous déjà eu des brûlures de tapis ou été blessé(e) physiquement à cause du sexe ?"
    },
    {
      "id": "homo",
      "question": "Avez-vous déjà eu des rapports homosexuels (ou contraires à votre appartenance sexuelle) ?"
    },
    {
      "id": "bdsm",
      "question": "Avez-vous déjà eu des expériences sado-masochistes ou pratiqué le bondage ?"
    },
    {
      "id": "sextoys",
      "question": "Avez-vous déjà utilisé des sex toys ?"
    },
    {
      "id": "endormiSexe",
      "question": "Vous êtes-vous déjà endormi(e) ou évanoui(e) pendant l'acte sexuel ?"
    },
    {
      "id": "perteVirginite",
      "question": "Avez-vous déjà été responsable de la perte de la virginité de quelqu'un, et si oui, de combien de personnes ?"
    },
    {
      "id": "acheterSexShop",
      "question": "Avez-vous déjà acheté quelque chose dans un sex-shop ?"
    },
    {
      "id": "sexeFamilial",
      "question": "Avez-vous déjà eu des relations sexuelles avec quelqu'un de votre famille ?"
    },
    {
      "id": "hesiter",
      "question": "...Avez-vous hésité avant de répondre à la question précédente ?"
    },
    {
      "id": "zoophilieToussa",
      "question": "Est-ce que la zoophilie, la nécrophilie ou la pédophilie vous attire ou vous excite ?"
    },
    {
      "id": "coupSoir",
      "question": "Avez-vous déjà eu un coup d'un soir ?"
    },
    {
      "id": "combienCoupSoir",
      "question": "Combien de fois ?"
    },
    {
      "id": "sexeSansAurevoir",
      "question": "Avez-vous déjà eu un coup d'une nuit, et quitté votre partenaire sans même lui dire au revoir ?"
    },
    {
      "id": "sexeMemeFamille",
      "question": "Avez-vous déjà eu des relations sexuelles avec plus d'une personne de la même famille ?"
    },
    {
      "id": "sexeDejaRelation",
      "question": "Avez-vous déjà eu des relations sexuelles avec quelqu'un qui était déjà engagée dans une relation avec quelqu'un d'autre ?"
    },
    {
      "id": "sexeMarie",
      "question": "Cette personne était-elle mariée ?"
    },
    {
      "id": "sexeAmiPartenaire",
      "question": "Avez-vous déjà eu des relations sexuelles avec un(e) ami(e) de votre partenaire officiel(le) ?"
    },
    {
      "id": "sexeDeuxFoisVieux",
      "question": "Avez-vous déjà eu des relations sexuelles avec quelqu'un qui était deux fois plus vieux que vous ?"
    },
    {
      "id": "bainMembreOppose",
      "question": "Avez-vous déjà pris un bain ou une douche avec un membre du sexe opposé ?"
    },
    {
      "id": "lecherOeil",
      "question": "Avez-vous déjà léché un oeil, un orteil, ou une oreille ?"
    },
    {
      "id": "dejaMasturbation",
      "question": "Vous êtes vous déjà masturbé(e) ?"
    },
    {
      "id": "masturbationTelephone",
      "question": "Vous êtes-vous déjà masturbé(e) en téléphonant ?"
    },
    {
      "id": "masturbationDeux",
      "question": "Masturbation mutuelle ?"
    },
    {
      "id": "doucheDoree",
      "question": "Avez-vous déjà participé à une douche dorée (uriner sur quelqu'un d'autre ou se faire uriner dessus par quelqu'un d'autre) ?"
    },
    {
      "id": "reveErotique",
      "question": "Avez vous déjà fait un rêve érotique ?"
    },
    {
      "id": "voyeur",
      "question": "Avez-vous déjà été voyeur(euse) ?"
    },
    {
      "id": "simulerOrgasme",
      "question": "Avez-vous déjà simulé l'orgasme ?"
    },
    {
      "id": "sortiSexe",
      "question": "Etes-vous déjà sorti(e) avec quelqu'un juste pour le sexe ?"
    },
    {
      "id": "sexePari",
      "question": "Avez-vous déjà eu des relations sexuelles juste pour gagner un pari ?"
    },
    {
      "id": "sexeCoupDeVent",
      "question": "Avez-vous déjà eu des relations sexuelles en coup de vent (entre deux cours, dans la rue, le tout dans l'urgence, sans préliminaires ou presque...) ?"
    },
    {
      "id": "sexePromotion",
      "question": "Avez-vous déjà eu des relations sexuelles pour avoir un travail ou une promotion ?"
    },
    {
      "id": "intimePublic",
      "question": "Avez-vous déjà exhibé vos parties intimes en public ?"
    },
    {
      "id": "indelite",
      "question": "Avez-vous déjà été infidèle ?"
    },
    {
      "id": "sexePartenaireAmi",
      "question": "Avez-vous déjà eu des relations sexuelles avec le/la partenaire d'un(e) de vos ami(e)s ?"
    },
    {
      "id": "sexeRegret",
      "question": "Avez-vous déjà eu des relations sexuelles que vous regrettez maintenant ?"
    },
    {
      "id": "telephoneSexe",
      "question": "Avez-vous déjà téléphoné ou répondu à un appel téléphonique pendant un acte sexuel ?"
    },
    {
      "id": "teleSexe",
      "question": "Avez-vous déjà regardé la télé durant un acte sexuel ?"
    },
    {
      "id": "dessinAnimeSexe",
      "question": "Vous êtes-vous déjà posé des questions sur la vie sexuelle des personnages de dessins animés (les Hentai ne comptent pas) ?"
    },
    {
      "id": "ouSuisJe",
      "question": "Vous êtes-vous déjà réveillé(e) en vous demandant où vous étiez ?"
    },
    {
      "id": "ouSuisJeAvecQui",
      "question": "Vous êtes-vous déjà réveillé(e) en vous demandant où vous étiez et qui était la personne à côté de vous ?"
    },
    {
      "id": "tatouer",
      "question": "Êtes-vous tatoué(e) quelque part ?"
    },
    {
      "id": "travaillerCaritative",
      "question": "Avez-vous déjà travaillé pour une oeuvre caritative ?"
    },
    {
      "id": "possedeBible",
      "question": "Possédez-vous une bible, coran... ?"
    },
    {
      "id": "donCaritative",
      "question": "Avez-vous déjà fait des dons d'argent (pour des oeuvres caritatives) ?"
    },
    {
      "id": "lieuCulte",
      "question": "Etes-vous déjà allé(e) dans un lieu de culte hormis pour un mariage, ou un enterrement ?"
    },
    {
      "id": "lieuCulteUneSemaine",
      "question": "Allez vous dans votre lieu de culte au moins une fois par semaine ?"
    },
    {
      "id": "ptitfilou",
      "question": "Avez-vous effectué un des actes de ce test exprès pour améliorer votre score ?"
    }
  ],
  "results": {
    "global": {
      "you-are": "Vous êtes",
      "your-score-is": "Votre score : ",
      "points": "point(s)",
      "reponses-positives": "Réponses positives",
      "moyenne-line": "Les scores peuvent aller de {minScore} à {maxScore}, la moyenne est de {moyenne} (sur {testsNumber} tests effectués)"
    },
    "purity-levels": {
      "tres-pur": "TRÈS PUR",
      "pur": "PUR",
      "plutot-pur": "PLUTÔT PUR",
      "impur": "IMPUR",
      "salement-impur": "SALEMENT IMPUR",
      "dangereux-impur": "DANGEREUX (ET IMPUR)",
      "ignoble-impur": "IGNOBLE (ET IMPUR)"
    },
    "category": {
      "sex": "Sexe",
      "alcohol": "Alcool",
      "drugs": "Drogue",
      "hygiene": "Hygiène",
      "moral": "Morale",
      "positives": "Positives"
    },
    "phrases": {
      "negatif": [
        "N'oubliez pas votre cabas pour faire votre marché après la messe, dimanche prochain...",
        "(Votre score est négatif : cela veut dire que Vous êtes une personne très pur(e) ou alors que Vous êtes trop jeune pour avoir fait ce test)"
      ],
      "10": [
        "Pour vous, les bonnes soeurs sont encore trop perverses !",
        "(Vous êtes considéré(e) comme une personne très pure ayant vécu peu d'expériences, peut-être par choix ou par manque de maturité ;-) )"
      ],
      "50": [
        "Arrêtez de survivre et essayez de vivre !",
        "(Fais de ta vie ce que tu veux en faire, le tout c'est de ne jamais rien regretter !)"
      ],
      "100": [
        "Ouais, vous utilisez votre main droite (ou gauche) pour vous branler, comme tout le monde !",
        "(Un score dans la moyenne, rien de bien transcendant, tout ça !)"
      ],
      "150": [
        "Juste supérieur à la moyenne. Tout ce qu'on peut vous dire, c'est que Vous êtes sorti(e) de chez vous quelque fois !",
        "(Un bon début ! Vous n'avez pas à rougir de qui que ce soit !)"
      ],
      "200": [
        "La vie n'est qu'un vaste terrain de jeu pour vous !",
        "(Votre passé a été plein de surprises et ce n'est pas fini, loin de là !)"
      ],
      "250": [
        "Vous vivez votre vie à fond sans peur du lendemain !",
        "(Un score qui vous donne le droit de clamer votre impureté à l'assistance :P)"
      ],
      "400": [
        "Ouais... On va essayer la camisole. Tout va bien, les messieurs en blancs s'occupent de tout.",
        "(Fais de ta vie ce que tu veux en faire, le tout c'est de ne jamais rien regretter !)"
      ],
      "max": [
        "Plus proche de l'animal que de l'homme (et encore), si vous pouviez vous lécher les parties génitales, vous ne sortiriez plus de chez vous",
        "(Bouh le gros dégueulasse !)"
      ]
    },
    "twitter": "J'ai fait {score} points au Test de Pureté #griffor ! Et toi ? :)"
  }
}
